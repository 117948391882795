<template>
 
  
  <div class="page">
     



    <template v-if="is_loading">
      Loading...
    </template>
    <template v-else-if="!token_data">

      <div class="wrong">
        <div>Wrong reset password link</div>
            
        <UiButton
            :name="'Resend'"
            :tag="'NuxtLink'"
            :type="'primary'"
            to="/auth/forgot-password"
            />     
      </div>
    </template>

    <template v-else>


      <h1>Reset password</h1>
    <br>

      <UiInput
      :label="$t('login_view_password')"
      :text="password"
      type="password"
      @change:text="password = $event"
      />

      <UiInput
        :label="'Repeat password'"
        :text="passwordRepeat"
        type="password"
        @change:text="passwordRepeat = $event"
        />
  

      <div class="actions"> 
        <UiButton
          :name="'Update password'"
          :type="'primary'"
          @click="resetPassword"
          :is-loading="isLoding"
          />        
        <UiButton
          :name="'Cancel'"
          :tag="'NuxtLink'"
          :type="'link'"
          to="/auth/signin"
          />          
      </div>  
  
    </template>

    
  </div>  

</template>

<script setup>
import { ref } from 'vue'

definePageMeta({
  layout: "short",
  auth: false
})

const password = ref('')
const passwordRepeat = ref('')
const router = useRouter();
const { $user, $popup, $api } = useNuxtApp();
const isLoding = ref(false)
const token = ref(router.currentRoute.value.query.key)

const validatePasswords = (val, val2) => {
  if(val.length < 6){
    $popup.error('Password must be at least 6 characters')
    return false
  }
  if(val !== val2){
    $popup.error('Passwords do not match')
    return false
  }
  return val === val2
}

const resetPassword = () => {
  if (!validatePasswords(password.value, passwordRepeat.value)) {
    return
  }

  isLoding.value = true

  $api
  .patchResetPassword(password.value, passwordRepeat.value, token.value)
  .then(() => {
    isLoding.value = false
    $popup.success('Password updated')
    router.push('/')
  })
  .catch((err) => {
    isLoding.value = false
    $popup.error(err.message)
  })
}



const loadData = async (id) => {
  return await $api.getForgotTokenState(id);  
}


const { pending: is_loading,  data: token_data, error: token_error } = await useLazyAsyncData('token_data', async () => await loadData(token.value))




</script>


<style scoped>
.page{
  padding: 2rem;
} 
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}

.wrong{
  text-align: center;
}
</style>
